import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import logoThanks from '../assets/logos/logo-thanks.svg'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import './ThanksTemplate.scss'

const ThanksTemplate = ({ location }) => {
    const data = useStaticQuery(graphql`
        query ThanksQuery {
            heroImage: contentfulAsset(contentful_id: { eq: "5JesQ5rnZcCtQoLGrUdH86" }) {
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1140) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                    publicURL
                }
            }
        }
    `)

    return (
        <Layout location={location}>
            <SEO title='Danke für deine Nachricht' />
            <div className='thanks-page'>
                <section className='section-hero mb-5'>
                    <Container className='section-hero--container' fluid='lg'>
                        <PreviewCompatibleImage
                            className='hero-image'
                            alt='Danke für deine Nachricht.'
                            imageInfo={{
                                image: data.heroImage.localFile,
                            }}
                        />
                    </Container>
                </section>
                <Container>
                    <div className='thanks-text-container'>
                        <div className='h2 mb-5 text-tint'>Super! Nicht mehr lange und dein Vierbeiner verschönert nun bald auch deine vier Wände.</div>
                        <img className='logo-brand' src={logoThanks} alt='' role='presentation' />
                    </div>
                </Container>
            </div>
        </Layout>
    )
}

export default ThanksTemplate
