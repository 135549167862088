import React from 'react'
import ThanksTemplate from '../../templates/ThanksTemplate'

const InquiryThanksPage = ({ location }) => {
    return (
        <ThanksTemplate location={location} />
    )
}

export default InquiryThanksPage
